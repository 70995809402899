<script setup>
import {defineProps} from "vue";
const props = defineProps({
  model: {
    type: Object,
    required: true,
    Title:	String,
    Url:	String,
    Summary:	String,
    Metadata:	String,
    ContentType:	String,
  },
  dictionary: {
    type: Object,
    required: true,
  },
})
</script>

<template>
	<li>
		<a class="bg-white mb d-block arrow-link-hover-wrapper block-card" :href="props.model.Url">
			<div>
				<span class="tags d-block mb">
					<span v-if="props.model.Metadata" class="tag tag--alt">{{props.model.Metadata}}</span>
					<span v-if="props.model.ContentType" class="tag tag--alt">{{props.model.ContentType}}</span>
				</span>
				<h2 v-if="props.model.Title" class="font-md font-bold mb">{{props.model.Title}}</h2>
			</div>
			<span v-if="props.dictionary.readMore" class="arrow-link font-bold mt">{{ props.dictionary.readMore }}</span>
		</a>
	</li>
</template>
