export default class ShareFunctions {
	constructor(config) {
		this.el = config.el;
		this.shareBtn = this.el.querySelector(".share-functions__button");
		this.shareFuntionList = this.el.querySelector(".share-functions__list");

		this.init();
	}
	init() {
		this.attachEvents();
	}

	toggleShareFunctionListDisplay() {
		const shareFunctionList = this.shareFuntionList;

		const isListDisplayed = shareFunctionList.style.display === "block";
		shareFunctionList.style.display = isListDisplayed ? "none" : "block";
	}
	attachEvents() {
		this.shareBtn.addEventListener("click", () => {
			this.toggleShareFunctionListDisplay();
		});

		document.addEventListener("click", (event) => {
			const shareFunctionList = this.shareFuntionList;
			if (!this.el.contains(event.target)) {
				shareFunctionList.style.display = "none";
			}
		});
	}
}
