<script setup>
import ListItem from "./ListItem.vue";
import {useQuery} from '@tanstack/vue-query'
import {ref, defineProps} from "vue";
import Pagination from "./Pagination.vue";
import Facets from "./Facets.vue";
import {debounce} from "throttle-debounce";

const props = defineProps({
  showFilter: {
    type: Boolean,
  },
  showSearch: {
    type: Boolean,
  },
  baseOrgId: {
    type: String,
  },
  baseSpecialtyIds: {
    type: String,
  },
  presentationUrl: {
    type: String,
  },
  isSearchFilterSubspecialty: {
    type: Boolean,
  },
  allFilterGuid: {
    type: String,
  },
  dictionary: {
    type: Object,
    //filters
    filterBy: String,
    viewAll: String,
    viewLess: String,
    filteringOptions: String,
    // //search
    searchButton: String,
    searchLabel: String,
    //results
    readMore: String,
    noResults: String,
    // //pagination
    choosePage: String,
    activePage: String,
    page: String,
    goToPage: String,
    goToPreviousPage: String,
    goToNextPage: String,
    previous: String,
    next: String,
  },
});

const PAGE_SIZE = 10;

const BASE_API_URI = "/api/ydelserapi"

const endpoints = {
  getListUri: ({query = "",
                facets = "",
                baseOrgIds = "",
                baseSpecialtyIds = "",
                filterIsSubspecialty = true,
                presentationUrl = "",
                page = 1 }) => {
    return `${BASE_API_URI}/GetYdelser?baseOrgIds=${baseOrgIds}&baseSpecialtyIds=${baseSpecialtyIds}&filterIsSubspecialty=${filterIsSubspecialty}&presentationUrl=${presentationUrl}&page=${page}&pageSize=${PAGE_SIZE}${query ? `&searchword=${query}` : ""}${facets ? `&filterId=${facets}` : ""}`
  },
  getFacetUri: ({ query = "",
                 facets = "",
                 baseOrgIds = "",
                 baseSpecialtyIds = "",
                 filterIsSubspecialty = true
                 }) => {
    return `${BASE_API_URI}/GetFilters?baseOrgIds=${baseOrgIds}&baseSpecialtyIds=${baseSpecialtyIds}&filterIsSubspecialty=${filterIsSubspecialty}${query ? `&searchword=${query}` : ""}${query ? `&searchword=${query}` : ""}${facets ? `&filterId=${facets}` : ""}`
  }
}
const fetchHandler = async (url) =>
await fetch(url)
    .then((response) =>
    response.json(),
)

let page = ref(1);
let query = ref("");
let facets = ref("");
const containerElement = ref(null);

const collectionQuery = useQuery({
  queryKey: ["collection", query, facets, page],
  queryFn: () => fetchHandler(endpoints
      .getListUri({
        page: page.value,
        query: query.value,
        facets: facets.value,
        baseOrgIds: props.baseOrgId,
        baseSpecialtyIds: props.baseSpecialtyIds,
        filterIsSubspecialty: props.isSearchFilterSubspecialty,
        presentationUrl: props.presentationUrl
      })),
      keepPreviousData: true,
      staleTime: Infinity,
})

const facetsQuery = useQuery({
  queryKey: ["facets", query],
  queryFn: () => fetchHandler(endpoints.getFacetUri({
    query: query.value,
    baseOrgIds: props.baseOrgId,
    baseSpecialtyIds: props.baseSpecialtyIds,
    filterIsSubspecialty: props.isSearchFilterSubspecialty
  })),
  keepPreviousData: true,
  staleTime: Infinity,
})

const handleQueryChange = (value) => {
  
  if (!value) {
    page.value = 1;
    query.value = "";
  }
  
  if (value && value.length <= 2) return;
  page.value = 1;
  query.value = value;
}

const handleQueryChangeDebounced = debounce(500, handleQueryChange);

const handleFacetsChange = (value) => {

  page.value = 1;
  facets.value = value;
}

const scrollToElement = (element) => {
  
  if (!element) return;
  
  window.scrollTo({
    top: element.offsetTop - 100,
    behavior: "smooth",
  });
};
const handlePrevPage = () => {
  page.value = Math.max(page.value - 1, 1);
  scrollToElement(containerElement.value);
}
const handleNextPage = () => {
    page.value = page.value + 1
  scrollToElement(containerElement.value);
}

const handleSetPage = (value) => {
  page.value = value;
  scrollToElement(containerElement.value);
}

const collectionIsLoading = collectionQuery.isPending || collectionQuery.isFetching

</script>

<template>
  <div class="container container--padded" ref="containerElement">
    <div class="row">
      <div class="col-12">
        <div class="ydelse-list">
          <Facets 
              @query="handleQueryChangeDebounced" 
              @facets="handleFacetsChange" 
              :reset-filter-id="props.allFilterGuid"
              :showFilter="props.showFilter" 
              :show-search="props.showSearch" 
              :facets="facetsQuery.data.value" 
              :dictionary="props.dictionary" />
          <div v-if="collectionIsLoading" class="list-loader">
            <div class="loader"></div>
          </div>
          <div v-else-if="collectionQuery.isError.value">An error has occurred: {{ collectionQuery.error.value }}</div>
          <ul  v-else-if="collectionQuery.data.value">
            <ListItem v-for="(item, index) in collectionQuery.data.value.ListItems" :key="index" :model="item" :dictionary="props.dictionary" />
          </ul>
          <div v-else class="mb-1 font-sm font-bold">
            <p>{{ props.dictionary.noResults }}</p>
          </div>
          <Pagination
              v-if="collectionQuery.data.value && collectionQuery.data.value.pageCount > 1" 
              @next="handleNextPage" 
              @prev="handlePrevPage" 
              @set-page="handleSetPage" 
              :max-pages="collectionQuery.data.value.pageCount" 
              :current-page="page" 
              :dictionary="props.dictionary" />
        </div>
      </div>
    </div>
  </div>  
</template>
