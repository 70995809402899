import { setAriaProp, isMatchingIds } from "@/base/utilities";
export default class HeaderSmall {
	constructor(config) {
		this.el = config.el || document.querySelectorAll(".header-small");
		this.menuCloseBtn = this.el.querySelector(".header-small__close");
		this.menuOpenBtn = this.el.querySelector(".header-small__burger");
		this.menuOpenClass = "header-small__panel--show";
		this.bodyOpenClass = "body--hide";
		this.body = document.body;
		this.backButton = this.el.querySelector(".header-small__back");
		this.backButtonParentId = null;
		this.menuPanel = this.el.querySelector(".header-small__panel");
		this.navigationsEl = this.el.querySelector(".header-small__navigations");
		this.currentId = this.navigationsEl.dataset.currentId;
		this.tabMenu = this.menuPanel.querySelector(".tab-menu");
		this.navMenuList = this.menuPanel.querySelector(".nav-menu__list");
		this.serviceMenu = this.menuPanel.querySelector(".service-menu");

		// mobile header search
		this.searchDisabled = this.el.dataset.searchDisabled === "True";
		this.searchOverlayEl = this.el.querySelector(".search-overlay");
		this.searchCloseBtn = this.el.querySelector(".header-small__search-close");
		this.searchInput = this.el.querySelector(".search-form__input");
		this.searchForm = this.el.querySelector(".header-small__cludo-search-form");
		this.searchOpen = false;

		this.init();
	}
	init() {
		this.attachEvents();
		this.loadMenu(this.currentId, true);
	}
	attachEvents() {
		this.menuOpenBtn.addEventListener("click", () => {
			this.showPanel();
		});
		this.menuCloseBtn.addEventListener("click", () => {
			this.hidePanel();
		});

		if (!this.searchDisabled) {
			this.searchInput.addEventListener(
				"input",
				() => {
					if (this.searchInput.value) {
						this.focusSearch();
					}
				},
				true
			);
			this.searchCloseBtn.addEventListener("click", () => {
				this.closeSearch(this.menuPanel);
			});
		}
		this.backButton.addEventListener("click", () => {
			this.goBack();
		});
	}

	focusSearch() {
		this.searchOpen = true;
		this.searchOverlayEl.classList.remove("invisible");
		this.searchCloseBtn.setAttribute("tabindex", 0);
		this.searchCloseBtn.classList.add("header-small__search-close--showing");
		this.navigationsEl.setAttribute("inert", "");
		this.backButton.setAttribute("inert", "");
	}
	closeSearch(elementToFocus) {
		this.searchOpen = false;
		this.searchOverlayEl.classList.add("invisible");
		this.searchCloseBtn.setAttribute("tabindex", -1);
		this.searchCloseBtn.classList.remove("header-small__search-close--showing");
		this.navigationsEl.removeAttribute("inert");
		this.backButton.removeAttribute("inert");
		elementToFocus.focus();
	}

	showPanel() {
		this.menuPanel.classList.add(this.menuOpenClass);
		setAriaProp(this.menuPanel, "hidden", false);
		this.menuPanel.style.visibility = "visible";
		this.menuPanel.removeAttribute("inert");
		this.body.classList.add(this.bodyOpenClass);

		this.focusTrap(true);
	}
	hidePanel() {
		this.menuPanel.classList.remove(this.menuOpenClass);
		setAriaProp(this.menuPanel, "hidden", true);
		this.menuPanel.style.visibility = "hidden";
		this.menuPanel.setAttribute("inert", "");
		this.body.classList.remove(this.bodyOpenClass);
		if (!this.searchDisabled) {
			this.closeSearch(this.menuOpenBtn);
		}

		this.focusTrap(false);
	}

	focusTrap(trap) {
		const elementsToMakeInert = document.querySelectorAll(
			".header__large, .header-small__top-menu, main, .main-footer"
		);
		elementsToMakeInert.forEach((el) => {
			if (trap) {
				el.setAttribute("inert", "");
			} else {
				el.removeAttribute("inert");
			}
		});
		if (trap) {
			this.menuPanel.focus();
		} else {
			this.menuOpenBtn.focus();
		}
	}

	loadMenu(id, initialLoad = false) {
		fetch(`/api/navigationapi/getmenu?pageItemId=${id}`)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (initialLoad && data.MenuItems.length === 0) {
					// landed on a page with no children. Move the burger menu one level up to list siblings instead of children.
					this.loadMenu(data.ParentId);
				} else {
					this.updateMenu(data, id);
				}
			})
			.catch((e) => console.error(e));
	}

	updateMenu(data, id) {
		this.backButtonParentId = data.ParentId;

		const isTopLevel = data.IsTabRootPage;
		this.updateBackButton(isTopLevel);

		this.navigationsEl.scrollTop = 0; // reset scroll of list back to 0, if the user has scrolled down on a previous navigation list

		if (isTopLevel) {
			this.tabMenu.removeAttribute("inert");
			this.tabMenu.classList.remove("tab-menu--hidden");
			this.serviceMenu.removeAttribute("inert");
			this.serviceMenu.classList.remove("service-menu--hidden");
		} else {
			this.tabMenu.setAttribute("inert", "");
			this.tabMenu.classList.add("tab-menu--hidden");
			this.serviceMenu.setAttribute("inert", "");
			this.serviceMenu.classList.add("service-menu--hidden");
		}

		const setAriaCurrent = (itemId) => {
			return isMatchingIds(itemId, this.currentId) ? 'aria-current="page"' : "";
		};

		let html = "";

		if (!isTopLevel) {
			html += `
			<li class="nav-menu__item">
				<a href="${data.MenuItemUrl}" ${setAriaCurrent(
				id
			)} class="nav-menu__link font-sm font-bold nav-menu__link--has-children nav-menu__link--big">${
				data.MenuTitle
			}</a>
			</li>`;
		}

		data.MenuItems.forEach((element) => {
			html += `
			<li class="nav-menu__item">
				<a data-item-id="${element.Id}" ${setAriaCurrent(element.Id)} href="${
				element.Url
			}" class="nav-menu__link font-bold ${
				element.HasChildren ? "nav-menu__link--has-children" : ""
			}">
					${element.MenuTitle}
				</a>
			</li>`;
		});

		this.navMenuList.innerHTML = html;
		this.navMenuList
			.querySelectorAll(
				".nav-menu__link--has-children:not(.nav-menu__link--big)"
			)
			.forEach((el) => {
				el.onclick = (e) => {
					e.preventDefault();
					this.loadMenu(el.dataset.itemId);
				};
			});
	}

	goBack() {
		this.loadMenu(this.backButtonParentId);
	}

	updateBackButton(hide) {
		if (hide) {
			this.backButton.classList.add("header-small__back--hide");
			setAriaProp(this.backButton, "hidden", true);
			this.backButton.setAttribute("tabindex", -1);

			if (document.activeElement === this.backButton) {
				this.navigationsEl.focus();
			}
		} else {
			this.backButton.classList.remove("header-small__back--hide");
			setAriaProp(this.backButton, "hidden", false);
			this.backButton.setAttribute("tabindex", 0);
		}
	}
}
